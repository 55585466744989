import React, { useCallback, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getDateFormat,
  getDateDisplayFormat,
  getDateTimeFormat,
  getDateTimeDisplayFormat,
  getCompositeId,
  getUserFormat,
  getActivityFeedUserFormat,
  getItemApprover,
  formatUser,
} from "utils/dataFormatters";
import { useGetSourcesInGroup } from "./IssuePage/Sources";
import { ITableRecordFormatting } from "common/TabbedTable";
import { BulkEdit } from "common/bulkEdit";
import { GetIssuev3Query } from "API";
import {
  formatSeverity,
  formatStatus,
  IssueStatus,
  formatObj,
  formatPriority,
  getStyleByStatus,
} from "utils/formatStatus";
import { HGRichTextRead } from "common/HGForm/HGRichText";
import { usePermissions } from "common/Permissions";
import {
  PriorityBadge,
  SeverityBadge,
  StatusBadge,
  RelatedItemBadge,
} from "common/Badges";
import {
  IssuePageModal,
  checkWorkflowStatus,
} from "./IssuePage/IssuePageModal";
import {
  getRelatedActionPlansSummary,
  getRelatedItemsSearchString,
} from "formatRelatedItemsSearchString";

type schemaParams = {
  showGroupPrefix?: boolean;
  userRole?: string;
  refetch?: any;
  updateIssue?: Function;
};

type IssueType = NonNullable<GetIssuev3Query["getIssuev3"]>;

export const useIssueSchema = (
  params?: schemaParams
): Array<ITableRecordFormatting<any>> => {
  const [isOpen, setIsOpen] = useState(false);
  const [issueId, setIssueId] = useState("");
  const { getOrganizationGroup, organizationUsers } = usePermissions();
  const { sources } = useGetSourcesInGroup();
  const issueStatus = formatObj(IssueStatus, formatStatus);
  const handleModalActions = (issue, event) => {
    if (issue.id === event) {
      setIssueId(event);
      setIsOpen(!isOpen);
    }
  };

  const archiveIssue = useCallback(
    (id: string, archived = true) => {
      params?.updateIssue?.({
        variables: {
          input: [
            {
              id,
              archived,
            },
          ],
        },
      });
    },
    [params?.updateIssue]
  );

  const issueSchema = [
    {
      id: "checkbox",
      name: "",
      format: () => "",
      tableDisplayFormat: (issue) => <BulkEdit item={issue} />,
      required: true,
      first: true,
    },
    {
      id: "compositeID",
      name: "ID",
      format: (issue) => `${getCompositeId(issue)}`,
      required: true,
      first: true,
    },
    {
      id: "title",
      name: "Title",
      format: (issue) => issue.title,
      tableDisplayFormat: (issue) => {
        return (
          <Link aria-label="issueTitle" to={`/issues/${issue.id}`}>
            {issue.title}
          </Link>
        );
      },
      required: true,
      first: true,
    },
    {
      id: "description",
      name: "Description",
      format: (issue) => issue.description,
      tableDisplayFormat: (issue) => (
        <HGRichTextRead content={issue.description} />
      ),
      defaultVisible: false,
    },
    {
      id: "acceptanceCriteria",
      name: "Resolution Criteria",
      format: (issue) => issue.acceptanceCriteria,
      tableDisplayFormat: (issue) => (
        <HGRichTextRead content={issue.acceptanceCriteria} />
      ),
      defaultVisible: false,
    },
    {
      id: "assumptions",
      name: "Assumptions",
      format: (issue) => issue.assumptions,
      tableDisplayFormat: (issue) => (
        <HGRichTextRead content={issue.assumptions} />
      ),
      defaultVisible: false,
    },
    {
      id: "notes",
      name: "Notes",
      format: (issue) => issue.notes,
      tableDisplayFormat: (issue) => <HGRichTextRead content={issue.notes} />,
      defaultVisible: false,
    },
    {
      id: "issueSourceId",
      name: "Source",
      format: (issue) => {
        const source = sources?.find((s) => issue.issueSourceId === s.id);
        return `${source?.title ?? "Not Set"}${
          source?.archived ? " (archived)" : ""
        }`;
      },
      defaultVisible: true,
    },
    {
      id: "status",
      name: "Status",
      format: ({ status }) => formatStatus(status),
      tableDisplayFormat: ({ status }) => <StatusBadge status={status} />,
      defaultVisible: true,
      type: "Options",
      optionValues: issueStatus,
    },
    {
      id: "severity",
      name: "Severity",
      format: (issue) => formatSeverity(issue.severity),
      tableDisplayFormat: ({ severity }) => (
        <SeverityBadge severity={severity} />
      ),
      sortFormat: (issue: IssueType) => {
        switch (issue.severity) {
          case "Null":
            return 0;
          case "Low":
            return 1;
          case "Minor":
            return 2;
          case "Major":
            return 3;
          case "Critical":
            return 4;
          default:
            return 1;
        }
      },
      defaultVisible: true,
      type: "Options",
    },
    {
      id: "priority",
      name: "Priority",
      format: (issue) => formatPriority(issue.priority),
      tableDisplayFormat: ({ priority }) => (
        <PriorityBadge priority={priority} />
      ),
      sortFormat: (issue: IssueType) => {
        switch (issue.priority) {
          case "Null":
            return 0;
          case "Low":
            return 1;
          case "Medium":
            return 2;
          case "High":
            return 3;
          case "Critical":
            return 4;
          default:
            return 0;
        }
      },
      defaultVisible: true,
      type: "Options",
    },
    {
      id: "owner",
      name: "Owner",
      format: getUserFormat("issueOwner"),
      tableDisplayFormat: (issue) => {
        const owner = getUserFormat("issueOwner")(issue);
        if (owner === "Not Set") {
          return (
            <Badge title={owner} pill>
              {owner}
            </Badge>
          );
        }
        return owner;
      },
      activityFeed: getActivityFeedUserFormat("owner"),
      defaultVisible: false,
    },
    {
      id: "approver",
      name: "Approver",
      format: (issue) => {
        return getItemApprover(issue, "issueApprovers");
      },
      tableDisplayFormat: (issue) => {
        const issueApprover = getItemApprover(issue, "issueApprovers");
        if (issueApprover === "Not Set") {
          return (
            <Badge title={issueApprover} pill>
              {issueApprover}
            </Badge>
          );
        }
        return issueApprover;
      },
      defaultVisible: false,
    },
    {
      id: "createdBy",
      name: "Created By",
      format: getUserFormat("issueCreatedBy"),
      defaultVisible: false,
    },
    {
      id: "modifiedBy",
      name: "Modified By",
      format: getUserFormat("issueModifiedBy"),
      defaultVisible: false,
    },
    {
      id: "verified by",
      name: "Verified By",
      format: getUserFormat("issueVerifiedBy"),
      tableDisplayFormat: (issue) => {
        const issueVerifiedBy = getUserFormat("issueVerifiedBy")(issue);
        if (issueVerifiedBy === "Not Set") {
          return (
            <Badge title={issueVerifiedBy} pill>
              {issueVerifiedBy}
            </Badge>
          );
        }
        return issueVerifiedBy;
      },
      defaultVisible: false,
    },
    {
      id: "reported By",
      name: "Reported By",
      format: (issue) => issue.reportedBy,
      defaultVisible: false,
    },
    {
      id: "reportedOn",
      name: "Reported On",
      type: "Date",
      format: getDateFormat("reportedOn"),
      displayFormat: getDateDisplayFormat("reportedOn"),
      defaultVisible: false,
    },
    {
      id: "nextRevision",
      name: "Review Date",
      type: "Date",
      format: getDateFormat("nextReview"),
      tableDisplayFormat: (issue) => (
        <p
          style={
            issue?.nextReview
              ? getStyleByStatus(issue?.status, issue?.nextReview)
              : undefined
          }
        >
          {getDateDisplayFormat("nextReview")(issue)}
        </p>
      ),
      defaultVisible: false,
    },
    {
      id: "createdAt",
      name: "Created At",
      type: "Date",
      format: getDateTimeFormat("createdAt"),
      displayFormat: getDateTimeDisplayFormat("createdAt"),
      defaultVisible: false,
    },
    {
      id: "requiresActionplan",
      name: "Requires Action Plan",
      format: (issue) =>
        issue.requiresActionplan !== null
          ? issue.requiresActionplan
            ? "Yes"
            : "No"
          : "Not Set",
      defaultVisible: false,
      tableDisplayFormat: ({ requiresActionplan }) => {
        return (
          <>
            {requiresActionplan !== null ? (
              <>{requiresActionplan ? "Yes" : "No"}</>
            ) : (
              <Badge>Not Set</Badge>
            )}
          </>
        );
      },
    },
    {
      id: "requiresScenario",
      name: "Requires Scenario",
      format: ({ requiresScenario }) =>
        requiresScenario !== null
          ? requiresScenario
            ? "Yes"
            : "No"
          : "Not Set",
      tableDisplayFormat: ({ requiresScenario }) => {
        return (
          <>
            {requiresScenario !== null ? (
              <>{requiresScenario ? "Yes" : "No"}</>
            ) : (
              <Badge>Not Set</Badge>
            )}
          </>
        );
      },
      defaultVisible: false,
    },
    {
      id: "lastModified",
      name: "Last Modified",
      type: "Date",
      format: getDateTimeFormat("lastModified"),
      displayFormat: getDateTimeDisplayFormat("lastModified"),
      defaultVisible: false,
    },
    {
      id: "closedDate",
      name: "Closed Date",
      type: "Date",
      format: getDateTimeFormat("dateClosed"),
      displayFormat: getDateTimeDisplayFormat("dateClosed"),
      defaultVisible: false,
    },
    {
      id: "actionPlans",
      name: "Related Action Plans",
      format: (issue) =>
        getRelatedItemsSearchString(
          issue.issueRelatedItems?.items,
          getRelatedActionPlansSummary
        ),
      tableDisplayFormat: (issue) => {
        const items = issue.issueRelatedItems?.items;
        return (
          <RelatedItemBadge
            items={items}
            display="actionplan"
            filter="actionplans"
          />
        );
      },
      required: true,
      last: true,
    },
    {
      id: "Workflow Status",
      name: "Workflow Status",
      format: (issue) => checkWorkflowStatus(issue),
      tableDisplayFormat: (issue) => {
        const workflowStatus = checkWorkflowStatus(issue);
        const colorClass =
          workflowStatus === "Intake Complete" ||
          workflowStatus === "Triage Prep Complete"
            ? "filled"
            : "normal";
        return (
          <>
            {isOpen && issue.id === issueId && (
              <IssuePageModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                issue={issue}
                updateIssue={params?.updateIssue}
              />
            )}
            <Badge
              id={issue.id}
              className={`${workflowStatus
                ?.split(" ")
                ?.join("")}-${colorClass}`}
              onClick={(e) => handleModalActions(issue, e.target?.id)}
              style={{ cursor: "pointer" }}
            >
              {workflowStatus}
            </Badge>
          </>
        );
      },
      type: "Options",
      optionValues: [
        "Intake Incomplete",
        "Intake Complete",
        "Triage Prep Incomplete",
        "Triage Prep Complete",
        "Deferred",
        "Act",
        "Monitor",
      ],
      required: true,
      last: true,
    },
    {
      id: "",
      name: "",
      format: (issue) => "",
      tableDisplayFormat: (issue) =>
        params?.userRole !== "read" ? (
          <Button
            variant="outline-primary"
            onClick={() => archiveIssue?.(issue.id, !issue.archived)}
          >
            {(issue.archived && "Unarchive") || "Archive"}
          </Button>
        ) : (
          <></>
        ),
      required: true,
      last: true,
    },
  ];

  params?.showGroupPrefix &&
    issueSchema.splice(1, 0, {
      id: "department",
      name: "Department",
      format: (actionPlan) =>
        `${
          params?.showGroupPrefix
            ? `${getOrganizationGroup(actionPlan.groupID)?.title} `
            : ""
        }`,
      required: true,
      first: true,
    });

  return issueSchema;
};
